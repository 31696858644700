<template>
  <ion-page>
    <template v-if="isLoaded">
      <ion-content>
        <div class="image-container">
          <span
            class="back-btn"
            @click="() => $router.replace('/tabs/quizlist')"
          >
            <ion-icon :icon="chevronBackOutline"></ion-icon
          ></span>
          <ion-img
            v-if="getDetail.media.length > 0"
            :src="`${getDetail.media[0].url}`"
          ></ion-img>
        </div>
        <div class="ion-padding">
          <h2>{{ getDetail.name }}</h2>
          <div>
            {{}}
            {{
              getDetail.is_chart_map == 0
                ? getDetail.countQuestions + "otázek"
                : ""
            }}
          </div>
          <p :class="!showed ? 'hidden' : ''">{{ getDetail.description }}</p>
          <p class="show-more" @click="showed = !showed">
            {{ showed ? "Zobrazit méně" : "Zobrazit více" }}
          </p>
          <div class="divider"></div>
          <div v-if="getDetail.is_chart_map != 1">
            <h4>Moje skóre</h4>
            <template
              v-if="
                getDetail.evaluation.countCorrectly != undefined &&
                  getDetail.evaluation.best_time != undefined &&
                  getDetail.evaluation.countAttempts != undefined
              "
            >
              <div class="score">
                <span class="stars">
                  <ion-icon
                    :icon="
                      getDetail.evaluation.countCorrectly >=
                      Math.round(getDetail.countQuestions / 3)
                        ? star
                        : starOutline
                    "
                    :class="
                      getDetail.evaluation.countCorrectly >=
                      Math.round(getDetail.countQuestions / 3)
                        ? 'full'
                        : 'empty'
                    "
                  ></ion-icon>
                  <ion-icon
                    :icon="
                      getDetail.evaluation.countCorrectly >=
                      Math.round(getDetail.countQuestions / 2)
                        ? star
                        : starOutline
                    "
                    :class="
                      getDetail.evaluation.countCorrectly >=
                      Math.round(getDetail.countQuestions / 2)
                        ? 'full'
                        : 'empty'
                    "
                  ></ion-icon>
                  <ion-icon
                    :icon="
                      getDetail.evaluation.countCorrectly ==
                      getDetail.countQuestions
                        ? star
                        : starOutline
                    "
                    :class="
                      getDetail.evaluation.countCorrectly ==
                      getDetail.countQuestions
                        ? 'full'
                        : 'empty'
                    "
                  ></ion-icon>
                </span>
                <span
                  >{{ getDetail.evaluation.countCorrectly }} z
                  {{ getDetail.countQuestions }} odpovědí správně</span
                >
              </div>
              <div class="attempts">
                <ion-grid>
                  <ion-row>
                    <ion-col size="4">
                      <ion-icon :icon="checkmarkCircleOutline"></ion-icon>
                      {{ getDetail.evaluation.countAttempts }}
                      pokusy</ion-col
                    >
                    <ion-col size="8">
                      <ion-icon :icon="stopwatchOutline"></ion-icon> nejlepší
                      čas: {{ getTime() }}</ion-col
                    >
                  </ion-row>
                </ion-grid>
              </div>
            </template>
            <template v-else>
              <p>Doposud nemáte žadný výsledek.</p>
            </template>
          </div>
        </div>
      </ion-content>
      <ion-footer class="ion-padding">
        <ion-button
          @click="
            $router.push({ name: 'Questions', params: { startQuestion: null } })
          "
          color="primary"
          expand="block"
          v-if="getDetail.evaluation.state == 1"
          :disabled="getDetail.countQuestions == 0"
          >Spustit</ion-button
        >
        <ion-button
          color="primary"
          expand="block"
          v-else-if="getDetail.evaluation.state == 2"
          @click="
            $router.push({
              name: 'Questions',
              params: {
                startQuestion: getDetail.evaluation.last_question_id,
              },
            })
          "
          >Pokračovat</ion-button
        >
        <ion-button
          color="primary"
          expand="block"
          v-else
          @click="
            $router.push({ name: 'Questions', params: { startQuestion: null } })
          "
          >Spustit znovu</ion-button
        >
      </ion-footer>
    </template>
    <template v-else>
      <div class="loading">
        <ion-spinner></ion-spinner>
      </div>
    </template>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonImg,
  IonContent,
  IonButton,
  IonIcon,
  IonFooter,
  IonCol,
  IonGrid,
  IonRow,
  IonSpinner,
} from "@ionic/vue";
import { mapActions, mapGetters } from "vuex";
import {
  chevronBackOutline,
  stopwatchOutline,
  checkmarkCircleOutline,
  starOutline,
  star,
} from "ionicons/icons";
import moment from "moment";

export default {
  data() {
    return {
      showed: false,
    };
  },
  components: {
    IonPage,
    IonContent,
    IonImg,
    IonButton,
    IonIcon,
    IonFooter,
    IonCol,
    IonGrid,
    IonRow,
    IonSpinner,
  },
  computed: {
    ...mapGetters({
      isLoaded: "quiz/isLoaded",
      getDetail: "quiz/getDetail",
    }),
  },
  async beforeMount() {
    let self = this;
    this.id = self.$route.params.id;
    await this.downloadQuizDetail({ id: this.id }).then((res) => {
      self.changeLoading({ loading: false });
    });
  },
  methods: {
    ...mapActions({
      downloadQuizDetail: "quiz/downloadQuizDetail",
      changeLoading: "quiz/changeLoading",
    }),
    getTime() {
      return moment()
        .startOf("day")
        .seconds(this.getDetail.evaluation.best_time)
        .format("HH [h] mm [m] ss [s]");
    },
  },
  setup() {
    return {
      chevronBackOutline,
      stopwatchOutline,
      checkmarkCircleOutline,
      starOutline,
      star,
    };
  },
};
</script>

<style scoped>
ion-content,
ion-footer,
ion-page {
  --ion-background-color: var(--ion-card-background);
  background: var(--ion-card-background);
}
h2,
h4 {
  font-weight: 700;
}
ion-icon {
  position: relative;
  top: 4px;
  left: 2px;
}
.back-btn {
  position: absolute;
  top: 25px;
  left: 25px;
  font-size: 27px;
  height: 35px;
  width: 35px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: #fff;
}
.back-btn .icon-inner {
  position: relative !important;
  top: 3px !important;
  left: 3px !important;
}
.hidden {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  padding-bottom: 0px;
}
.show-more {
  color: var(--ion-color-primary);
}
.divider {
  border-bottom: 3px solid var(--ion-color-light);
  padding-top: 16px;
  margin-bottom: 16px;
}
.attempts {
  color: #2f855a;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
}
.attempts ion-icon {
  position: relative;
  top: 5px;
  font-size: 20px;
  margin-right: 4px;
}
ion-grid {
  padding: 0px !important;
}
ion-col {
  padding: 0px !important;
}
.stars {
  font-size: 28px;
  margin-right: 24px;
  position: relative;
  top: 3px;
}
.stars ion-icon {
  margin-right: 8px;
}
.full {
  color: var(--ion-color-primary);
}
.empty {
  color: var(--ion-color-medium);
}
.score {
  color: var(--ion-color-medium);
  margin: 16px 0px;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
ion-img::part(image) {
  max-height: 25vh;
  object-fit: cover;
  object-position: top;
}
.image-container {
  padding-top: var(--ion-safe-area-top);
  position: relative;
}
</style>
